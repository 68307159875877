
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { PROFILE_INPUTS, PROFILE_PASSWORD_INPUTS } from "@/store/common/profile";
import { CopyIcon } from '@/plugins/icons';

import CommonInputs from '@/components/common/inputs/index.vue';
import CommonButton from '@/components/common/button/Button.vue';
import ProfileAvatar from './Avatar.vue';

import { useAPI } from "@/use";
import { ROLES } from "@/utils/enums";

export default defineComponent({
  name: 'ProfileMainMain',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      profile: PROFILE_INPUTS(this.user),
      password: PROFILE_PASSWORD_INPUTS,
    }
  },
  methods: {
    async submit() {
      const result = await useAPI().user.updateMethod(this.profile.data);
      this.$store.commit('updateUserData', result.data);
    },
    async submitPassword() {
      await useAPI().user.updatePasswordMethod(this.password.data);
    },
    async updateAvatar(formData: FormData) {
      const result = await useAPI().file.updateAvatarUserMethod(formData);
      this.$store.commit('updateUserAvatar', result.data.avatar);
    },
    async deleteAvatar() {
      await useAPI().file.deleteAvatarUserMethod();
    },
    async copyPromocode() {
      const result = await useAPI().user.getPromocodeMethod();
      this.copy(result.data.code);

      this.notification('Промокод скопирован в буфер обмена!');
    },
    async copyReferral() {
      const result = await useAPI().user.getReferralMethod();
      this.copy(`${process.env.VUE_APP_DEV_HOST}/registration?referral=${result.data.link}`);
      
      this.notification('Реферальная ссылка скопирована в буфер обмена!');
    },
    modal(id: string) {
      this.$store.commit('createModal', { id, data: this.user });
    },
    notification(message: string) {
      this.$store.commit('createNotification', {
        message,
        status: 'success'
      })
    },
    copy(copy: string) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(copy);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = copy;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand('copy') ? res(copy) : rej();
          textArea.remove();
        });
      }
    }
  },
  computed: {
    isPartner() {
      return this.user.role === ROLES.PARTNER && this.user.verification === true;
    },
    isNotAdmin() {
      return this.user.role !== ROLES.ADMIN && this.user.role !== ROLES.OWNER;
    },
  },
  components: {
    CommonInputs,
    ProfileAvatar,
    CommonButton,
    CopyIcon,
  }
})
